import React from 'react'
import Layout from '../components/Layout'

export default class TalleresPage extends React.Component {
  render() {

    return (
      <Layout>
        <section className="section">
          <div className="container">
            Hello talleres
          </div>
        </section>
      </Layout>
    )
  }
}
